<template>
  <b-card-actions :title="$t('Filter')" action-collapse>
    <b-row class="filter">
      <!-- Transaction Id -->
      <b-col cols="12" md="2" class="mb-2">
        <label>{{ $t("Transaction id") }}</label>
        <b-form-input
          v-model="filter.reference"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          class="w-100"
        />
      </b-col>
      <!-- From Date Fiter -->
      <b-col cols="12" md="2" class="mb-2">
        <b-form-group label="Date From" label-for="fromDate">
          <flat-pickr
            id="fromDate"
            v-model="filter.fromdate"
            class="form-control"
            placeholder="YYYY-MM-DD HH:II:SS"
            :config="flatpickrConfig"
          />
          <!-- @on-change="changeFromDate" -->
        </b-form-group>
      </b-col>
      <!-- To Date Filter -->
      <b-col cols="12" md="2" class="mb-2">
        <b-form-group label="Date To" label-for="toDate">
          <flat-pickr
            id="toDate"
            v-model="filter.todate"
            class="form-control"
            placeholder="YYYY-MM-DD HH:II:SS"
            :config="flatpickrConfig"
          />
          <!-- @on-change="changeToDate" -->
        </b-form-group>
      </b-col>
      <!-- Bonus Code -->
      <b-col cols="12" md="2" class="mb-2">
        <label>Bonus Code</label>
        <v-select
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          v-model="filter.bonus_code"
          :options="bonusCodeOption"
          class="w-100"
          :reduce="(val) => val.id"
          label="name"
        />
      </b-col>
      <!-- Username -->
      <b-col cols="12" md="2" class="mb-2">
        <label>{{ $t("Login Name") }}</label>
        <b-form-input
          v-model="filter.username"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          class="w-100"
        />
      </b-col>
      <!-- Source -->
      <b-col cols="12" md="2" class="mb-md-0 mb-2">
        <label>{{ $t("Source") }}</label>
        <v-select
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          v-model="filter.balance_type_from"
          :options="balanceType"
          class="w-100"
          :reduce="(val) => val.id"
          label="name"
        />
        <!-- @input="(val) => $emit('update:balanceTypeFromFilter', val)" -->
      </b-col>
      <!-- Destination -->
      <b-col cols="12" md="2" class="mb-md-0 mb-2">
        <label>{{ $t("Destination") }}</label>
        <v-select
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          v-model="filter.balance_type_to"
          :options="balanceType"
          class="w-100"
          :reduce="(val) => val.id"
          label="name"
        />
        <!-- @input="(val) => $emit('update:balanceTypeToFilter', val)" -->
      </b-col>
      <!-- Status -->
      <b-col cols="12" md="2" class="mb-md-0 mb-2">
        <label>{{ $t("Status") }}</label>
        <v-select
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          v-model="filter.status"
          :options="statusOptions"
          class="w-100"
          :reduce="(val) => val.value"
        />
        <!-- @input="(val) => $emit('update:statusFilter', val)" -->
      </b-col>
      <!-- Action button -->
      <b-col cols="12" md="4" class="d-flex align-items-end justify-content-start" style="padding-bottom: 10px">
        <b-button variant="outline-secondary" @click="resetFilter()">
          {{ $t("Reset") }}
        </b-button>
        <b-button variant="primary" class="ml-2" @click="searchFilter()">
          {{ $t("Search") }}
        </b-button>
      </b-col>
    </b-row>
  </b-card-actions>
</template>

<script>
import { BButton, BFormGroup, BFormInput, BRow, BCol } from "bootstrap-vue";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";

export default {
  components: {
    BCardActions,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
    flatPickr,
  },
  props: {
    referenceFilter: {
      type: [String, null],
      default: null,
    },
    refNumberFilter: {
      type: [String, null],
      default: null,
    },
    partnerRefNumberFilter: {
      type: [String, null],
      default: null,
    },
    usernameFilter: {
      type: [String, null],
      default: null,
    },
    fromdateFilter: {
      type: [String, null],
      default: null,
    },
    todateFilter: {
      type: [String, null],
      default: null,
    },
    bonusCodeFilter: {
      type: [Number, null],
      default: null,
    },
    statusFilter: {
      type: [Number, null],
      default: null,
    },
    balanceTypeFromFilter: {
      type: [Number, null],
      default: null,
    },
    balanceTypeToFilter: {
      type: [Number, null],
      default: null,
    },
    statusOptions: {
      type: Array,
      required: true,
    },
    balanceType: {
      type: Array,
      required: true,
    },
    bonusCodeOption: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      filter: {
        reference: this.referenceFilter,
        ref_number: this.refNumberFilter,
        partner_ref_number: this.partnerRefNumberFilter,
        username: this.usernameFilter,
        fromdate: this.fromdateFilter,
        todate: this.todateFilter,
        bonus_code: this.bonusCodeFilter,
        balance_type_from: this.balanceTypeFromFilter,
        balance_type_to: this.balanceTypeToFilter,
        status: this.statusFilter,
      },
      flatpickrConfig: {
        dateFormat: "Y-m-d H:i:S",
        enableTime: true,
        time_24hr: true,
      },
    };
  },
  methods: {
    changeFromDate() {
      this.$emit("update:fromdateFilter", this.filter.fromdate);
    },
    changeToDate() {
      this.$emit("update:todateFilter", this.filter.todate);
    },
    resetFilter() {
      this.filter = {
        reference: null,
        ref_number: null,
        partner_ref_number: null,
        username: null,
        fromdate: null,
        todate: null,
        bonus_code: null,
        balance_type_from: null,
        balance_type_to: null,
        status: null,
      };
      this.$emit("update:referenceFilter", null);
      this.$emit("update:refNumberFilter", null);
      this.$emit("update:partnerRefNumberFilter", null);
      this.$emit("update:usernameFilter", null);
      this.$emit("update:fromdateFilter", null);
      this.$emit("update:todateFilter", null);
      this.$emit("update:bonusCodeFilter", null);
      this.$emit("update:balanceTypeFromFilter", null);
      this.$emit("update:balanceTypeToFilter", null);
      this.$emit("update:statusFilter", null);
      this.$emit("refetch-data");
    },
    searchFilter() {
      this.$emit("update:referenceFilter", this.filter.reference);
      this.$emit("update:refNumberFilter", this.filter.ref_number);
      this.$emit(
        "update:partnerRefNumberFilter",
        this.filter.partner_ref_number
      );
      this.$emit("update:usernameFilter", this.filter.username);
      this.$emit("update:fromdateFilter", this.filter.fromdate);
      this.$emit("update:todateFilter", this.filter.todate);
      this.$emit("update:bonusCodeFilter", this.filter.bonus_code);
      this.$emit("update:balanceTypeFromFilter", this.filter.balance_type_from);
      this.$emit("update:balanceTypeToFilter", this.filter.balance_type_to);
      this.$emit("update:statusFilter", this.filter.status);
      this.$emit("refetch-data");
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
