import TransferService from '@/libs/transfer.service'

export default {
	namespaced: true,
	state: {},
	getters: {},
	mutations: {},
	actions: {
		fetchTransfers(ctx, queryParams) {
			return new Promise((resolve, reject) => {
				TransferService.getTransferLists(queryParams)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
		addTransfer(ctx, queryParams) {
			return new Promise((resolve, reject) => {
				TransferService.addTransfer(queryParams)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
		getBonusCodeList(ctx, queryParams) {
			return new Promise((resolve, reject) => {
				TransferService.getBonusCodeList()
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
    }
}
