/* eslint-disable vue/html-self-closing */
<template>
  <div>
    <!-- Filters -->
    <transfers-list-filters
      v-if="balanceType"
      :reference-filter.sync="referenceFilter"
      :ref-number-filter.sync="refNumberFilter"
      :partner-ref-number-filter.sync="partnerRefNumberFilter"
      :username-filter.sync="usernameFilter"
      :fromdate-filter.sync="fromdateFilter"
      :todate-filter.sync="todateFilter"
      :bonus-code-filter.sync="bonusCodeFilter"
      :status-filter.sync="statusFilter"
      :balance-type-from-filter.sync="balanceTypeFromFilter"
      :balance-type-to-filter.sync="balanceTypeToFilter"
      :status-options="statusOptions"
      :bonus-code-option="bonusCodeOption"
      :balance-type="balanceType"
      @refetch-data="refetchData"
    />

    <b-card no-body class="mb-0">
      <b-table
        ref="refTransferListTable"
        class="position-relative table-white-space max-height-table"
        sticky-header
        head-variant="light"
        :no-border-collapse="true"
        :items="fetchTransfers"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + data.index + 1 }}
        </template>

        <template #cell(ref)="data">
          <b-link @click="showTransferDetail(data.item)">
            {{ data.item.ref }}
          </b-link>
        </template>

        <!-- Column: User -->
        <template #cell(username)="data">
          <b-link
            :to="{ name: 'apps-users-view', params: { id: data.item.user_id } }"
            v-if="data.item.user"
          >
            {{ data.item.user.username }}
          </b-link>
        </template>

        <template #cell(fromto)="data">
          {{ data.item.balance_type_from.name_en }}
          <feather-icon icon="ArrowRightIcon" />
          {{ data.item.balance_type_to.name_en }}
        </template>

        <template #cell(currency)="data">
          {{ data.item.user ? data.item.user.currency : "VND" }}
        </template>

        <template #cell(bonus_amount)="data">
          <span class="text-primary">
            {{
              typeof data.item.amount_bonus != "undefined"
                ? numberFormat(data.item.amount_bonus / 1000)
                : 0
            }}
          </span>
        </template>

        <template #cell(amount)="data">
          <span class="text-primary">{{
            numberFormat(data.item.amount / 1000)
          }}</span>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <div :class="`text-capitalize align-items-center d-flex`">
            <span
              :class="`dot bg-${
                resolveStatus(data.item.txn_status.id).variant
              }`"
            />{{ data.item.txn_status.name }}
          </div>
        </template>

        <template #cell(created_at)="data">
          {{ formatDateTime(data.item.created_at).replace(".000000Z", "") }}
        </template>

        <template #cell(updated_at)="data">
          {{ formatDateTime(data.item.updated_at).replace(".000000Z", "") }}
        </template>

        <!-- Column: Actions -->
        <template #cell(action)="data">
          <!-- <b-button
						v-if="data.item.status == 1"
						variant="success"
						class="btn-icon btn-sm mr-50"
						@click="approveAdjustmentModal(data.item, 2)"
					>
						<feather-icon icon="CheckIcon" />
					</b-button>
					<b-button
						v-if="data.item.status == 1"
						variant="danger"
						class="btn-icon btn-sm mr-50"
						@click="approveAdjustmentModal(data.item, 3)"
					>
						<feather-icon icon="XIcon" />
					</b-button> -->
          <b-button
            v-b-tooltip.hover.v-info
            :title="$t('Detail')"
            variant="outline-secondary"
            class="btn-icon btn-sm"
            @click="showTransferDetail(data.item)"
          >
            <feather-icon icon="EyeIcon" />
          </b-button>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalTransfers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <!-- Transfer Detail Modal -->
    <transfer-detail-modal :transfer-data.sync="transferData" />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BLink,
  BPagination,
  VBTooltip,
} from "bootstrap-vue";
import { onUnmounted, ref } from "@vue/composition-api";
import {
  avatarText,
  numberFormat,
  resolveCurrency,
  formatDateTime,
} from "@core/utils/filter";
import store from "@/store";
import TransfersListFilters from "./TransfersListFilters.vue";
import useTransfersList from "./useTransfersList";
import transferStoreModule from "./transferStoreModule";
import userStoreModule from "@/views/apps/user/userStoreModule";

export default {
  components: {
    TransfersListFilters,
    TransferDetailModal: () => import("./TransferDetailModal.vue"),
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BLink,
    BPagination,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      transferData: null,
      type: null,
    };
  },
  methods: {
    showTransferDetail(transferData) {
      this.transferData = transferData;
      this.$bvModal.show("modal-transfer-detail");
    },
  },
  setup() {
    const PAYMENT_TRANSFER_STORE_MODULE_NAME = "payment-transfer";
    const USER_APP_STORE_MODULE_NAME = "app-user";

    // Register module
    if (!store.hasModule(PAYMENT_TRANSFER_STORE_MODULE_NAME))
      store.registerModule(
        PAYMENT_TRANSFER_STORE_MODULE_NAME,
        transferStoreModule
      );
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PAYMENT_TRANSFER_STORE_MODULE_NAME))
        store.unregisterModule(PAYMENT_TRANSFER_STORE_MODULE_NAME);
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const bonusCodeOption = ref([]);

    const getBonusCodeList = (ctx, callback) => {
      store
        .dispatch('payment-transfer/getBonusCodeList', {})
        .then(response => {
          bonusCodeOption.value = response.data.data;
        })
    }

    getBonusCodeList();

    const {
      fetchTransfers,
      tableColumns,
      perPage,
      currentPage,
      totalTransfers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refTransferListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveStatus,

      // Extra Filters
      usernameFilter,
      bonusCodeFilter,
      refNumberFilter,
      partnerRefNumberFilter,
      fromdateFilter,
      todateFilter,
      referenceFilter,
      statusFilter,
      balanceTypeFromFilter,
      balanceTypeToFilter,

      statusOptions,
      balanceType,
    } = useTransfersList();

    return {
      fetchTransfers,
      tableColumns,
      perPage,
      currentPage,
      totalTransfers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refTransferListTable,
      refetchData,

      // Filter
      avatarText,
      numberFormat,
      formatDateTime,
      resolveCurrency,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveStatus,

      statusOptions,
      balanceType,

      // Extra Filters
      usernameFilter,
      bonusCodeFilter,
      refNumberFilter,
      partnerRefNumberFilter,
      fromdateFilter,
      todateFilter,
      referenceFilter,
      statusFilter,
      balanceTypeFromFilter,
      balanceTypeToFilter,

      bonusCodeOption,
    };
  },
};
</script>
<style scoped>
.max-height-table {
  max-height: 580px;
}
</style>
