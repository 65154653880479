/* eslint-disable indent */
/* eslint-disable class-methods-use-this */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable global-require */
/* eslint-disable no-useless-concat */
/* eslint-disable no-useless-escape */
// import axios from 'axios'
import instance from '@/libs/axios.instance'
import authHeader from '@/libs/auth.header'
import { paginateArray, sortCompare } from '@core/utils/filter'

class TransferService {

	getTransferLists(queryParams) {
		console.log('queryParams', queryParams)
		const pars = {
			username: queryParams.username,
			userId: queryParams.userId,
			email: queryParams.email,
			name: queryParams.fullname,
			phone: queryParams.phone,
			reference: queryParams.reference,
			fromDate: queryParams.fromdate,
			toDate: queryParams.todate,
			createdBy: queryParams.createdBy,
			status: queryParams.stat,
			pageNumber: queryParams.page,
			pageSize: queryParams.perPage,
			balanceTypeFrom: queryParams.balanceTypeFrom,
			balanceTypeTo: queryParams.balanceTypeTo,
			bonus_code_id: queryParams.bonus_code
		}
		return instance.get('/api/Transfer/index', { headers: authHeader(), params: pars })
				.then(
					response => {
						const {
							q = '',
							perPage = 10,
							page = 1,
							sortBy = 'id',
							sortDesc = false,
							stat = 1,
						} = queryParams
						const queryLowered = q.toLowerCase()
						const filteredData = response.data.data.filter(
							transfer =>
								(transfer.ref.toLowerCase().includes(queryLowered) || transfer.user.username.toLowerCase().includes(queryLowered)) &&
								transfer.status === (stat || transfer.status)
						)
						const sortedData = filteredData.sort(sortCompare(sortBy))
						if (sortDesc) sortedData.reverse()

						const datas = {
							transfers: (queryLowered) ? paginateArray(sortedData, perPage, page) : response.data.data,
							total: (queryLowered) ? filteredData.length : response.data.count,
							pageSize: queryParams.page == 1 ? response.data.data.length : 25
						}
						return datas
					}
				)
	}

	addTransfer(userData) {
		const FormData = require('form-data')
		const data = new FormData()
		data.append('loginname', userData.loginname)
		data.append('balance_type_from', userData.balance_type_from)
		data.append('balance_type_to', userData.balance_type_to)
		data.append('amount', userData.amount * 1000)
		data.append('bonus_code', userData.bonusCode)

		return instance.post('/api/Transfer/create', data, { headers: authHeader() })
	}

	getBonusCodeList() {
        return instance.get("/api/bonus-code/index", {
            headers: authHeader(),
        });
    }
}

export default new TransferService()
