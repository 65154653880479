import { ref, watch, computed } from "@vue/composition-api";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import i18n from "@/libs/i18n";

export default function useTransfersList() {
  // Use toast
  const toast = useToast();

  const refTransferListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "index", label: "#" },
    { key: "ref", label: i18n.t("Transaction id") },
    { key: "username", label: i18n.t("Username") },
    { key: "fromto", label: `${i18n.t("Source")} > ${i18n.t("Destination")}` },
    // { key: 'bonus_code', label: 'Bonus Code' },
    // { key: 'currency', label: i18n.t('Currency') },
    { key: "bonus_amount", label: "Bonus Amount" },
    { key: "amount", label: i18n.t("Amount") },
    { key: "status", label: i18n.t("Status") },
    { key: "created_at", label: i18n.t("Created at") },
    // { key: 'updated_at', label: i18n.t('Updated at') },
    {
      key: "action",
      label: i18n.t("Actions"),
      stickyColumn: true,
      class: "text-center",
    },
  ];
  const statusOptions = ref([]);
  const perPage = ref(25);
  const totalTransfers = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const usernameFilter = ref(null);
  const referenceFilter = ref(null);
  const fromdateFilter = ref(null);
  const todateFilter = ref(null);
  const statusFilter = ref(null);
  const balanceTypeFromFilter = ref(null);
  const balanceTypeToFilter = ref(null);
  const bonusCodeFilter = ref(null);
  const refNumberFilter = ref(null);
  const partnerRefNumberFilter = ref(null);

  const balanceType = ref(null);

  const refetchData = () => {
    refTransferListTable.value.refresh();
  };

  watch(
    [
      currentPage,
      searchQuery,
      usernameFilter,
      bonusCodeFilter,
      refNumberFilter,
      partnerRefNumberFilter,
      referenceFilter,
      fromdateFilter,
      todateFilter,
      statusFilter,
      balanceTypeFromFilter,
      balanceTypeToFilter,
    ],
    () => {
      refetchData();
    }
  );

  const fetchTransfers = (ctx, callback) => {
    store
      .dispatch("payment-transfer/fetchTransfers", {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        username: usernameFilter.value,
        bonus_code: bonusCodeFilter.value,
        refNumber: refNumberFilter.value,
        parnerRefNumber: partnerRefNumberFilter.value,
        reference: referenceFilter.value,
        fromdate: fromdateFilter.value,
        todate: todateFilter.value,
        stat: statusFilter.value,
        balanceTypeFrom: balanceTypeFromFilter.value,
        balanceTypeTo: balanceTypeToFilter.value,
      })
      .then((response) => {
        const { transfers, total, pageSize } = response;
        callback(transfers);
        totalTransfers.value = total;
        perPage.value = pageSize;
      })
      .catch((error) => {
        const { response } = error;
        toast({
          component: ToastificationContent,
          props: {
            title: response.data.message || "Error fetching transfers list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  store.dispatch("app-user/fetchUserBalanceType").then((response) => {
    balanceType.value = response.data.data;
  });

  store
    .dispatch("app-user/fetchUserAdjustmentStatusOptions")
    .then((response) => {
      statusOptions.value = response.data.data.map((val) => {
        return {
          label: val.name,
          value: val.id,
        };
      });
    });

  const dataMeta = computed(() => {
    const localItemsCount = refTransferListTable.value
      ? refTransferListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalTransfers.value,
    };
  });

  const resolveUserRoleVariant = (role) => {
    if (role === "subscriber") return "primary";
    if (role === "author") return "warning";
    if (role === "maintainer") return "success";
    if (role === "editor") return "info";
    if (role === "admin") return "danger";
    return "primary";
  };

  const resolveUserRoleIcon = (role) => {
    if (role === "subscriber") return "UserIcon";
    if (role === "author") return "SettingsIcon";
    if (role === "maintainer") return "DatabaseIcon";
    if (role === "editor") return "Edit2Icon";
    if (role === "admin") return "ServerIcon";
    return "UserIcon";
  };

  const resolveStatus = (stat) => {
    if (stat === 1) return { variant: "warning", label: "Pending" };
    if (stat === 2) return { variant: "primary", label: "Inprocess" };
    if (stat === 3) return { variant: "danger", label: "Fail" };
    if (stat === 4) return { variant: "success", label: "Success" };
    return { label: "None", variant: "secondary" };
  };

  return {
    fetchTransfers,
    tableColumns,
    perPage,
    currentPage,
    totalTransfers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refTransferListTable,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveStatus,
    refetchData,

    // Extra Filters
    usernameFilter,
    bonusCodeFilter,
    refNumberFilter,
    partnerRefNumberFilter,
    referenceFilter,
    fromdateFilter,
    todateFilter,
    statusFilter,
    balanceTypeFromFilter,
    balanceTypeToFilter,

    statusOptions,
    balanceType,
  };
}
